import Chart from "chart.js/auto";

let charts = {};
charts.grafico_transacionadoProduto = {}
charts.grafico_balancoFinanceiro = {}
charts.grafico_performanceBriefing = {}
charts.grafico_conversaoBriefings = {}
charts.grafico_transacionado = {}
charts.grafico_receita = {}
charts.grafico_ticketMedio = {}
charts.grafico_cleintesMaiorReceita = {}

export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
  data() {
    return {
      meses: [
        "Jan",
        "Fev",
        "Mar",
        "Abr",
        "Maio",
        "Jun",
        "Jul",
        "Ago",
        "Set",
        "Out",
        "Nov",
        "Dez",
      ],
      ReceitaClientes: [
      ],
      year: 2022,
      loading: true,
      loading_2: true
    };
  },

  mounted() {
    this.apiBriefings();
    this.appiCompanies();
  },

  methods: {
    async apiBriefings(){
      let response = await axios
      .get('/api/v1/analytics/briefings?year='+ this.year, {
        headers: {
          Authorization: 'Bearer ' + this.TOKEN,
        }
      });

      if(response){
        this.loading = false
        this.transacionadoProduto(response);
        this.balancoFinanceiro(response);
        this.performanceBriefing(response);
        this.conversaoBriefings(response);
        this.transacionado(response); 
        this.receita(response);
      }
    },

    async appiCompanies(){
      let response = await axios
        .get('/api/v1/analytics/companies', {
          headers: {
            Authorization: 'Bearer ' + this.TOKEN,
          }
      });

      if(response){
        this.loading_2 = false
        this.ticketMedio(response);
        this.cleintesMaiorReceita(response);
      }

    },


    changeYear(y){
      this.year = y;
      this.loading = true
      this.loading_2 = true

      charts.grafico_transacionadoProduto.destroy()
      charts.grafico_balancoFinanceiro.destroy()
      charts.grafico_performanceBriefing.destroy()
      charts.grafico_conversaoBriefings.destroy()
      charts.grafico_ticketMedio.destroy()
      this.ReceitaClientes = []

      this.apiBriefings();
      this.appiCompanies();
    },

    transacionadoProduto(response) {
      var open = []
      var community = []
      var business = []
      var creators = []

      for (var i = 0; i < 12; i++) {
        open[i] = response.data.briefings.filter(b => parseInt(b.created_at.substr(5, 2)) == (i + 1) && b.plan == 1).length;
      }
      for (var i = 0; i < 12; i++) {
        community[i] = response.data.briefings.filter(b => b.status == 4 && b.plan == 2 &&  parseInt(b.created_at.substr(5,2)) == (i + 1)).length
      }

      for (var i = 0; i < 12; i++) {
        business[i] = response.data.briefings.filter(b => b.status == 4 && b.plan == 3 && parseInt(b.created_at.substr(5,2)) == (i + 1)).length
      }

      for (var i = 0; i < 12; i++) {
        creators[i] = response.data.briefings.filter(b => b.status == 4 && b.plan == 4 && parseInt(b.created_at.substr(5,2)) == (i + 1)).length
      }
      const ctx = document.getElementById("transacionadoProduto");
      const transacionadoProduto = new Chart(ctx, {
        type: "bar",
        data: {
          labels: this.meses,
          datasets: [
            {
              label: "Open",
              backgroundColor: "#74E7D0",
              data: open,
            },
            {
              label: "Community",
              backgroundColor: "#7553E2",
              data: community,
            },
            {
              label: "Business",
              backgroundColor: "#F2C94C",
              data: business,
            },
            {
              label: "Creators Pay",
              backgroundColor: "#589fdd",
              data: creators,
            },
            
          ],
        },
        options: {
          plugins: {
            legend: false, // Hide legend
          },
        },
      });


      charts.grafico_transacionadoProduto = transacionadoProduto;
    },

    balancoFinanceiro(response) { 
      let community_plan = [];
      let enterprise_plan = [];
      let normal_plan = [];
      var mes
      var normal = []
      var community = []
      var enterprise = []
      let list = []
      var transacionado = []
      var arrray_transacionado = []
      var briefings = response.data.briefings

      const reducer = (accumulator, curr) => accumulator + curr;
      
      function valueNomal (value){
        return Math.round(value = value / 0.85 * 0.15);
      }
      function valuecommunity (value){
        return Math.round(value = value / 0.85 * 0.15);
      }
      function valueEnterprise (value){
        return Math.round(value = value / 0.75 * 0.25);
      }

      function getValues(arr){
        var meses = [[],[],[],[],[],[],[],[],[],[],[],[]]
        arr.forEach(element => {  
          mes = parseInt(element.created_at.substr(5,2)) -1
          meses[mes].push(element.value)
        });
        return meses
      }
      function verify(arr){
        if(arr[i].length == 0){
          arr[i].push(0)
        }  
        return arr
      }

      normal_plan = getValues(briefings.filter(briefing =>  briefing.status == 4 && briefing.plan == 1));
      community_plan = getValues(briefings.filter(briefing =>  briefing.status == 4 && briefing.plan == 2));
      enterprise_plan = getValues(briefings.filter(briefing =>  briefing.status == 4 && briefing.plan == 3));     
      arrray_transacionado = getValues(briefings.filter(b => b.status == 4))

      for (var i = 0; i < 12; i++) {
        verify(normal_plan)
        verify(enterprise_plan)
        verify(community_plan)
        verify(arrray_transacionado)
           
        normal.push(valueNomal(normal_plan[i].reduce(reducer)/100))
        community.push(valueEnterprise(enterprise_plan[i].reduce(reducer)/100))
        enterprise.push(valuecommunity(community_plan[i].reduce(reducer)/100))
        transacionado.push(arrray_transacionado[i].reduce(reducer)/100)
      }

      list.push(normal, enterprise, community)
      const receita = list[0].map((x, idx) => list.reduce((sum, curr) => sum + curr[idx], 0));    
      
      for (var i = 0; i < 12; i++) {       
        if(transacionado[i] == 0){
         transacionado[i] = null
        }
 
        if(receita[i] == 0){
         receita[i] = null
        }
      }
     
      const ctx = document.getElementById("balancoFinanceiro");
      const labels = [
        "Jan",
        "Fev",
        "Mar",
        "Abr",
        "Mai",
        "Jun",
        "Jul",
        "Ago",
        "Set",
        "Out",
        "Nov",
        "Dez",
      ];
      const data = {
        labels: labels,
        datasets: [
          {
            label: "Transacionado",
            data: transacionado,
            borderColor: "#7553E2",
            tension: 0.1,
          },

          {
            label: "Receita",
            data: receita,
            borderColor: "#F2C94C",
            tension: 0.1,
          },
        ],
      };

      const balancoFinanceiro = new Chart(ctx, {
        type: "line",
        data: data,
        options: {
          plugins: {
            legend: false, // Hide legend
          },
        },
      });

      charts.grafico_balancoFinanceiro = balancoFinanceiro;
    },

    performanceBriefing(response) {
      var mes
      var mes2
      var meses = [[],[],[],[],[],[],[],[],[],[],[],[]]
      var meses2 = [[],[],[],[],[],[],[],[],[],[],[],[]]

      var transacionado = []
      var quantidade = []

      const reducer = (accumulator, curr) => accumulator + curr;
      var briefings = response.data.briefings.filter(b => b.status == 4)

      briefings.forEach(briefing => {  
        mes = parseInt(briefing.created_at.substr(5,2)) -1
        meses[mes].push(briefing.value)
      })

      briefings.forEach(briefing => {  
        mes2 = parseInt(briefing.created_at.substr(5,2)) -1
        meses2[mes2].push(briefing.id)
      })
      
      for (var i = 0; i < 12; i++) {
        if(meses[i].length == 0 ){
          meses[i].push(0)
        } 
        transacionado.push(meses[i].reduce(reducer)/100)
        quantidade.push(meses2[i].length)
      }
      for (var i = 0; i < 12; i++) {       
        if(quantidade[i] == 0){
         quantidade[i] = null
        }
 
        if(transacionado[i] == 0){
         transacionado[i] = null
        }
      }
      const ctx = document.getElementById("performanceBriefing");
      const labels = [
        "Jan",
        "Fev",
        "Mar",
        "Abr",
        "Mai",
        "Jun",
        "Jul",
        "Ago",
        "Set",
        "Out",
        "Nov",
        "Dez",
      ];
      const data = {
        labels: labels,
        datasets: [
          {
            label: "Briefings",
            data: quantidade,
            backgroundColor: "#7553E2",
            borderColor: "#7553E2",
            pointStyle: "circle",
            pointRadius: 2,
          },

          {
            label: "Valor $",
            data: transacionado,
            backgroundColor: "#74E7D0",
            borderColor: "#74E7D0",
            pointStyle: "circle",
            pointRadius: 2,
          },
        ],
      };

      const performanceBriefing = new Chart(ctx, {
        type: "line",
        data: data,
        options: {
          plugins: {
            legend: false, // Hide legend
          },
        },
      });

      charts.grafico_performanceBriefing = performanceBriefing;
    },

    conversaoBriefings(response) {
      var budget = []
      var approved_array= []
      var approved = []

      for (var i = 0; i < 12; i++) {
        budget[i] = response.data.briefings.filter(c => parseInt(c.created_at.substr(5, 2)) == (i + 1)).length;
      }

      approved_array = response.data.briefings.filter(c => c.approved_at != null);
     
      for (var i = 0; i < 12; i++) {       
        approved[i] = response.data.briefings.filter(c => c.status == 4 && parseInt(c.created_at.substr(5, 2)) == (i + 1)).length;
      }

      for (var i = 0; i < 12; i++) {       
       if(approved[i] == 0){
        approved[i] = null
       }

       if(budget[i] == 0){
        budget[i] = null
       }
      }

      const ctx = document.getElementById("conversaoBriefings");
      const labels = [
        "Jan",
        "Fev",
        "Mar",
        "Abr",
        "Mai",
        "Jun",
        "Jul",
        "Ago",
        "Set",
        "Out",
        "Nov",
        "Dez",
      ];
      const data = {
        labels: labels,
        datasets: [
          {
            label: "Publicado",
            data: budget,
            borderColor: "#7553E2",
            tension: 0.1,
          },

          {
            label: "Aprovado",
            data: approved,
            borderColor: "#F2C94C",
            tension: 0.1,
          },
        ],
      };

      const conversaoBriefings = new Chart(ctx, {
        type: "line",
        data: data,
        options: {
          plugins: {
            legend: false, // Hide legend
          },
        },
      });
      charts.grafico_conversaoBriefings = conversaoBriefings;
    },

    ticketMedio(response) {
      var mes
      var total_hirings
      var meses = [[],[],[],[],[],[],[],[],[],[],[],[]]
      var ticket = []
      const reducer = (accumulator, curr) => accumulator + curr;
      var companies = response.data.companies.filter(c => c.created_at.substr(0,4) == this.year)
      
      companies.forEach(company => { 
        company.hirings.forEach(hiring => {
          mes = parseInt(hiring.created_at.substr(5,2)) - 1
          meses[mes].push(hiring.value)         
        })
      })

      for (var i = 0; i < 12; i++) {
        if(meses[i].length == 0){
         meses[i].push(0)
        }      
       ticket.push(Math.round(meses[i].reduce(reducer)/meses[i].length/100))
      }

      for (var i = 0; i < 12; i++) {       
        if(ticket[i] == 0){
         ticket[i] = null
        }
      }

      const ctx = document.getElementById("ticketMedio");
      const labels = [
        "Jan",
        "Fev",
        "Mar",
        "Abr",
        "Mai",
        "Jun",
        "Jul",
        "Ago",
        "Set",
        "Out",
        "Nov",
        "Dez",
      ];
      const data = {
        labels: labels,
        datasets: [
          {
            label: "Ticket Médio",
            data: ticket,
            backgroundColor: "#7553E2",
            borderColor: "#7553E2",
            pointStyle: "circle",
            pointRadius: 2,
          },
        ],
      };

      const ticketMedio = new Chart(ctx, {
        type: "line",
        data: data,
        options: {
          plugins: {
            legend: false, // Hide legend
          },
        },
      });
      charts.grafico_ticketMedio = ticketMedio;
      
    },

     cleintesMaiorReceita(response){

      var clientes = []
      var total = []
      var clientes_limit

      const reducer = (accumulator, curr) => accumulator + curr;
      const formCurrency = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
        minimumFractionDigits: 2
      })

      var companies = response.data.companies.filter(c => c.hirings.length != 0) 

      companies.forEach(company => {
        var  arr_companies_values = []
        var values = []

        company.hirings.forEach(hiring => {
          if(hiring.created_at.substr(0,4) == this.year && hiring.approved_at != null){
            values.push(hiring.value)
          }
        })

        var value

        if(values.length != 0){
          value = values.reduce(reducer)       
          total.push(value)
          
          clientes.push({
            company: company.id,
            value: value,
            color: "#4285F4"
          })
        }
      })

      clientes.sort(function (a, b) {
        if (a.value > b.value) {
          return -1;
        }          
      });
      
      clientes_limit = clientes.slice(0,10)
        
      clientes_limit.forEach(r =>{
        var percent = (r.value * 100)/ total.reduce(reducer)
        var v = r.value/100
        this.ReceitaClientes.push({ 
          city: r.company,
          percent: Math.round(percent),
          color: "#74E7D0",
          invest: formCurrency.format(v)
        })
      })   

    },

    transacionado(response){
      var transacionado  
      var transacionado_last_year
      var values_last_year = response.data.values_last_year  
      const reducer = (accumulator, curr) => accumulator + curr;
      const formCurrency = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
        minimumFractionDigits: 2
      })
      function array_values(arr){
        var values = []
        arr.forEach(element => {  
          values.push(element.value)
        });
        return values
      }
      function verify(retorno){ 
        var plan
        if(retorno.length == 0 ){
          plan = 0
        }else{
          plan = retorno.reduce(reducer);
        }
        return plan
      }
      function formatPercent(p){
        var str = new String(p)
       return str.replace('-', '')
      }

      transacionado_last_year = Math.round(verify(array_values(response.data.values_last_year)))/100
      transacionado = Math.round(array_values(response.data.briefings.filter(b => b.status == 4)).reduce(reducer))/100


      if(transacionado_last_year == 0){
        var percent = (transacionado - transacionado_last_year) * 100/ transacionado
      } else {
        var percent = (transacionado - transacionado_last_year) * 100/ transacionado_last_year
      }

      document.getElementById("transacionado").innerHTML = formCurrency.format(transacionado)
      document.getElementById("transacionado_percent").innerHTML = Math.round(formatPercent(percent))+"%"

      if(transacionado < transacionado_last_year){
        document.getElementById("icon_transacionado").classList.add("icon-number-red")
      } else{
        document.getElementById("icon_transacionado").classList.add("icon-number")
      }
    },

    receita(response){
      var normal_plan
      var community_plan
      var enterprise_plan
      var receita
      var return_community
      var values_last_year = response.data.values_last_year

      function valueNormal (value){
        return Math.round(value = value / 0.85 * 0.15);
      }
      function valuecommunity (value){
        return Math.round(value = value / 0.85 * 0.15);
      }
      function valueEnterprise (value){
        return Math.round(value = value / 0.75 * 0.25);
      }
      function array_values(arr){
        var values = []
        arr.forEach(element => {  
          values.push(element.value)
        });
        return values
      }
      function verify(retorno){ 
        var plan
        if(retorno.length == 0 ){
          plan = 0
        }else{
          plan = retorno.reduce(reducer)/10;
        }
        return plan
      }
      function formatPercent(p){
        var str = new String(p)
       return str.replace('-', '')
      }
      const reducer = (accumulator, curr) => accumulator + curr;
      const formCurrency = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
        minimumFractionDigits: 2
      })

      //receita ultimo ano
      var normal_last_year = valueNormal(verify(array_values(values_last_year.filter(b =>  b.plan == 1 && b.status == 4))));
      var community_last_year = valuecommunity(verify(array_values(values_last_year.filter(b =>  b.plan == 2 && b.status == 4))));
      var enterprise_last_year = valueEnterprise(verify(array_values(values_last_year.filter(b =>  b.plan == 3 && b.status == 4)))); 
      var receita_last_year = normal_last_year + community_last_year + enterprise_last_year

      //receita

      normal_plan = Math.round(valueNormal(verify(array_values(response.data.briefings.filter(b =>  b.plan == 1 && b.status == 4)))));
      community_plan =  Math.round(valuecommunity(verify(array_values(response.data.briefings.filter(b =>  b.plan == 2 && b.status == 4)))));
      enterprise_plan = Math.round(valueEnterprise(verify(array_values(response.data.briefings.filter(b =>  b.plan == 3 && b.status == 4)))));  
      receita = (normal_plan + community_plan + enterprise_plan)

      if(receita_last_year == 0){
        var percent = (receita - receita_last_year) * 100/ receita

      } else {
        var percent = (receita - receita_last_year) * 100/ receita_last_year

      }


      document.getElementById("receita").innerHTML = formCurrency.format(receita)
      document.getElementById("receita_percent").innerHTML = Math.round(formatPercent(percent))+"%"
      if(receita < receita_last_year){
        document.getElementById("icon_receita").classList.add("icon-number-red")
      } else{
        document.getElementById("icon_receita").classList.add("icon-number")
      }
    }
  },
};